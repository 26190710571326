import website from '../assets/website.png'
import instagram from '../assets/instagram.png'
import discord from '../assets/discord.png'
import twitter from '../assets/twitter.png'

const items = [
     {
         "title": "Discord",
         "subtitle": "Join our Discord server 💯",
         "image": discord,
         "link": "https://discord.gg/puffinpresidents" //Discord Profile link
    },
    {
        "title": "Twitter",
        "subtitle": "@puffnpresidents | Follow Us 😉",
        "image": twitter,
        "link": "https://twitter.com/PuffnPresidents"// twitter profile link 
    },
    {
    "title": "Website",
    "subtitle": "Home Of Puffin Presidents ☘️",
    "image": website,
    "link": "https://www.puffinpresidents.io" 
    }
    // {
    // "title": "Instagram",
    // "subtitle": "Hub of sneakpeak from collection 👀",
    // "image": instagram,
    // "link": "https://www.instagram.com/puffinpresidents" //instagram profile link 
    // }
]

export default items
