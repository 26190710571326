import React from 'react'

import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Footer() {
    return (
        <div className="Footer container">
            <p>datNFTeam 2022© <a href='https://puffinpresidents.io'>https://datNFTteam.io</a> <br></br>All Rights Reserved</p>
        </div>
    )
}

export default Footer
